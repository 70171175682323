
/* Typography */
.custom-title {
font-size: 1.5rem !important;
font-weight: bold !important;
margin-bottom: 12px !important;
}

.custom-subtitle {
font-size: 1.2rem !important;
color: #666666 !important;
margin-bottom: 32px !important;
}

/* Styles par défaut pour assurer que l'avatar est visible sur les écrans non-mobiles */
button[aria-label="Current User"] .MuiAvatar-root {
    display: flex; /* ou la valeur par défaut appropriée */
  }
  
/* Masquer l'avatar uniquement sur mobile */
@media (max-width: 600px) {
button[aria-label="Current User"] .MuiAvatar-root {
    display: none !important;
}
}




.section-title {
font-size: 1.5rem;
font-weight: 600;
}

.dashboard-text {
font-size: 1rem;
}

.stat-number {
font-size: 2.5rem;
font-weight: bold;
}

/* Colors */
.dashboard-container {
background-color: #f5f5f5;
}

.primary-color {
color: #1976d2;
}

.secondary-color {
color: #dc004e;
}

.text-primary {
color: #333333;
}

.text-secondary {
color: #666666;
}

/* Spacing */
.dashboard-card {
padding: 24px;
margin-bottom: 32px;
}

/* Borders and shadows */
.dashboard-card,
.dashboard-button {
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Animations */
@keyframes countUp {
from {
    opacity: 0;
    transform: translateY(20px);
}
to {
    opacity: 1;
    transform: translateY(0);
}
}

.animate-count-up {
animation: countUp 1.5s ease-out;
}

/* Transitions */
.dashboard-card:hover,
.dashboard-button:hover {
transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
transform: translateY(-5px);
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Layout */
.dashboard-container {
max-width: 1200px;
margin: 0 auto;
padding: 24px;
}

/* Responsive */
@media (max-width: 600px) {
.dashboard-title {
    font-size: 1.5rem;
}

.dashboard-subtitle {
    font-size: 1rem;
}

.section-title {
    font-size: 1.2rem;
}

.stat-number {
    font-size: 2rem;
}

.dashboard-card {
    padding: 16px;
    margin-bottom: 24px;
}
}

/* Chart styles */
.recharts-wrapper {
font-size: 12px;
}

.recharts-tooltip-wrapper {
background-color: rgba(255, 255, 255, 0.9);
border: 1px solid #ccc;
border-radius: 4px;
padding: 8px;
}